import axios from 'axios';
import { ElMessage } from 'element-plus';
import { download } from '@tmaito/utils';
// var baseUrl = 'http://192.168.0.119:9888';
var baseUrl = 'http://47.97.178.209';
// var baseUrl = 'https://chuiyang.solarqt.com:9169';
export default {
  namespaced: true,
  state: () => ({
    loading: false,
    total: 0,
    dataSource: [],
    page: {
      page: 1,
      limit: 20
    },
    filterParams: {
      brandName: '',
      comModel: ''
    }
  }),
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setFilterParams(state, params) {
      state.filterParams = { ...params };
    },
    setData(state, res) {
      state.dataSource = [...res?.data];
      state.total = res?.total;
    },
    setPage(state, res) {
      state.page = {
        ...state.page,
        ...res
      };
    },
    setModal(state, res) {
      state = {
        ...state,
        ...res
      };
    }
  },
  actions: {
    // 登录逻辑
    queryData(context, params) {
      const { commit, state } = context;
      commit('setLoading', true);
      return new Promise((resolve, reject) => {
        const page = {
          ...state.page,
          ...(params?.page || {})
        };
        const filterParams = {
          ...state.filterParams,
          ...(params?.filterParams || {})
        };
        axios({
          url: baseUrl + '/api/Dict/PageData',
          method: 'POST',
          data: {
            ...page,
            params: {
              ...filterParams
            }
          }
        })
          .then(({ data }) => {
            if (data.code === 200) {
              commit('setPage', { ...page });
              commit('setFilterParams', { ...filterParams });
              const { total = 0, items = [] } = data?.data;
              commit('setData', {
                total,
                data: [...items]
              });
            } else {
              ElMessage.error(data.message);
            }
            resolve(data);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          })
          .finally(() => {
            setTimeout(() => {
              commit('setLoading', false);
            }, 600);
          });
      });
    },

    // 添加 or 修改
    addOrUpdate(context, data) {
      const { commit, dispatch } = context;
      commit('setLoading', true);
      data.spacing *= 1
      return new Promise((resolve, reject) => {
        axios({
          url: baseUrl + `/api/Dict/${data?.id ? 'Update' : 'Add'}`,
          method: 'POST',
          data
        })
          .then(({ data }) => {
            if (data.code === 200) {
              ElMessage.success('操作成功！');
              dispatch('queryData');
            } else {
              ElMessage.error(data.message);
            }
            resolve(data);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          })
          .finally(() => {
            setTimeout(() => {
              commit('setLoading', false);
            }, 600);
          });
      });
    },

    // 删除
    delete(context, id) {
      const { commit, dispatch } = context;
      commit('setLoading', true);
      return new Promise((resolve, reject) => {
        axios({
          url: baseUrl + `/api/Dict/Delete/${id}`,
          method: 'POST'
        })
          .then(({ data }) => {
            if (data.code === 200) {
              ElMessage.success('删除成功！');
              dispatch('queryData');
            } else {
              ElMessage.error(data.message);
            }
            resolve(data);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          })
          .finally(() => {
            setTimeout(() => {
              commit('setLoading', false);
            }, 600);
          });
      });
    },

    // 导出
    export(context, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: baseUrl + '/api/Dict/Export',
          method: 'POST',
          headers: {
            responseType: 'bolb'
          },
          data
        })
          .then(({ data }) => {
            if (data.code === 200) {
              download(`${location.origin}/${data.data}`);
            } else {
              ElMessage.error(data.message);
            }
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  }
};

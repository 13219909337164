import { createStore } from 'vuex';
import axios from 'axios';
import getters from './getters';

const DEFAULT_OPTIONS = {
  // BASE_URL: 'http://192.168.0.119:9888',
  BASE_URL: 'http://47.97.178.209',
  // BASE_URL: 'https://chuiyang.solarqt.com:9169',
  TIMEOUT: 1 * 60 * 1000,
  CONTENT_TYPE: 'application/json; charset=UTF-8'
};

axios.defaults.baseURL = DEFAULT_OPTIONS.BASE_URL;
axios.defaults.timeout = DEFAULT_OPTIONS.TIMEOUT;
// axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] = DEFAULT_OPTIONS.CONTENT_TYPE;

/**
 * 导入模块
 */
const path = require('path');
const file = require.context('./modules', true, /\.js/);
let modules = {};
file.keys().forEach(key => {
  const name = path.basename(key, '.js');
  modules[name] = file(key).default || file(key);
});

export default createStore({
  getters,
  modules
});

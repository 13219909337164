import { createRouter, createWebHashHistory } from 'vue-router';
import store from '@/store';
import Layout from '@/layout';
import project from './modules/project';

// 私有路由
export const privateRoutes = [project];

// 公开路由
export const publicRoutes = [
  {
    path: '/login',
    component: () => import('@/views/Login')
  },
  // {
  //   path: '/',
  //   component: () => import('@/views/project/atlas')
  // },
  {
    path: '/',
    name: '/',
    // redirect: '/home',
    redirect: '/project/atlas',
    component: Layout,
    children: [
      {
        path: '/home',
        name: '首页',
        component: () => import('@/views/Home/index'),
        // component: () => import('@/views/Project/Atlas'),
        // component: () => import('@/views/Project/manage'),
        meta: {
          title: '首页'
        }
      },
      // 404
      {
        path: '/404',
        name: '404',
        component: () => import('@/views/error-page/404')
      },
      {
        path: '/401',
        name: '401',
        component: () => import('@/views/error-page/401')
      }
    ]
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: publicRoutes
});

// 白名单
const whiteList = ['/login', '/home'];
/**
 * @description: 路由前置守卫
 * @param {*} to 要到哪里去
 * @param {*} from 从哪里来
 * @param {*} next  是否要去
 * @return {*}
 */
router.beforeEach(async (to, from, next) => {
  if (store.getters.token || sessionStorage.getItem('token')) {
    if (to.path === '/login') {
      next('/');
    } else {
      if (!store.getters.isLoadMenu) {
        const menusList = ['project'];
        const filterRoutes = await store.dispatch('menu/filterRoutes', menusList);
        filterRoutes.forEach(item => {
          router.addRoute(item);
        });
        next({ ...to, replace: true });
      }
      next();
    }
  } else {
    if (whiteList.indexOf(to.path) > -1) {
      next();
    } else {
      next('/login');
    }
  }
});
export default router;

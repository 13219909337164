<template>
  <el-menu :default-active="activeMenu" background-color="#1891ff" text-color="#fff" active-text-color="#fff"
    :unique-opened="true" router :collapse="false">
    <SidebarItem v-for="item in routes" :key="item.path" :route="item"></SidebarItem>
  </el-menu>
</template>

<script setup>
import { computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import SidebarItem from './SidebarItem.vue';
import { filterRoutes, generateMenus } from '@/utils';

const router = useRouter();

const routes = computed(() => {
  const fRoutes = filterRoutes(router.getRoutes());
  return generateMenus(fRoutes);
});
const route = useRoute();
const activeMenu = computed(() => {
  const { path } = route;
  return path;
});
</script>

<style lang="scss" scoped>
  ::v-deep .el-menu-item {
    &.is-active {
      background-color: #1374cc;
    }
  }
</style>

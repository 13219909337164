<template>
  <el-breadcrumb class="breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item, index) in breadcrumbData" :key="item.path">
        <!-- 不可点击项 -->
        <span v-if="index === breadcrumbData.length - 1" class="no-redirect">{{ item.meta.title }}</span>
        <!-- 可点击项 -->
        <a v-else class="redirect" @click.prevent="onLinkClick(item)">{{ item.meta.title }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script setup>
  import { ref, watch } from 'vue';
  // import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  // const store = useStore();

  const route = useRoute();
  const breadcrumbData = ref([]);
  const getBreadcrumbData = () => {
    breadcrumbData.value = route.matched.filter((item) => item.meta && item.meta.title);
  };

  const router = useRouter();
  const onLinkClick = (item) => {
    console.log(item);
    router.push(item.path);
  };

  watch(
    route,
    () => {
      getBreadcrumbData();
    },
    {
      immediate: true,
    },
  );
</script>

<style lang="scss" scoped>
  .breadcrumb {
    display: inline-block;
    font-size: 14px;
    line-height: 48px;
    margin-left: 24px;
    .redirect {
      color: #666;
      font-weight: 600;
    }

    ::v-deep .no-redirect {
      color: #97a8be;
      cursor: text;
    }
}
</style>

<template>
  <div className="app-main">
    <router-view v-slot="{ Component }">
      <transition name="fade-transform" mode="out-in">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>
  </div>
</template>

<style lang="scss" scoped>
  .app-main {
    position: fixed;
    top: 48px;
    right: 0;
    bottom: 0;
    left: 210px;
    overflow: hidden;
    padding: 24px;
    box-sizing: border-box;
    > div {
      height: 100%;
    }
  }
</style>

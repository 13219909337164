import axios from 'axios';
import router from '@/router';
// import qs from "qs";
// import { productCode} from "@/utils/config";

export default {
  namespaced: true,
  state: () => ({
    token: '',
    userInfo: {}
  }),
  mutations: {
    setToken(state, token) {
      sessionStorage.setItem('token', token);
      state.token = token;
    },
    setUserInfo(state, userInfo) {
      sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
      state.userInfo = userInfo;
    }
  },
  actions: {
    // 登录逻辑
    // login(context, userInfo) {
    //   const { commit } = context;
    //   const { username, password } = userInfo;
    //   return new Promise((resolve, reject) => {
    //     axios({
    //       url: 'http://47.92.200.142:9963/api/Users/UserLogin',
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded',
    //       },
    //       data: qs.stringify({
    //         UserCode: username,
    //         PassWord: password,
    //         ProductCode: productCode
    //       })
    //     })
    //       .then(({ data:res }) => {
    //         if (res.code === 0) {
    //           commit('setToken', res.msg);
    //           sessionStorage.setItem('DealerCode', res.data.DealerCode)
    //         }
    //         commit('setUserInfo', res?.data);
    //         resolve();
    //       })
    //       .catch(err => {
    //         reject(err);
    //       });
    //   });
    //   // return new Promise((resolve, reject) => {
    //   //   axios({
    //   //     url: 'http://47.92.200.142:9963/api/Auth/ProUserLogin',
    //   //     method: 'POST',
    //   //     headers: {
    //   //       "Content-type": "application/x-www-form-urlencoded"
    //   //     },
    //   //     data: qs.stringify({
    //   //       Mobile: username,
    //   //       ProductCode: "huyongguangfu"
    //   //     })
    //   //   })
    //   //       .then(({ data }) => {
    //   //         commit('setToken', data?.data?.id);
    //   //         commit('setUserInfo', data?.data);
    //   //         resolve();
    //   //       })
    //   //       .catch(err => {
    //   //         reject(err);
    //   //       });
    //   // });
    // },
    // 退出登录逻辑
    login(context, userInfo) {
      const { commit } = context;
      const { username, password } = userInfo;
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/User/Login',
          method: 'POST',
          data: {
            userName: username,
            passWord: password
          }
        })
            .then(({ data }) => {
              commit('setToken', data?.data?.id);
              commit('setUserInfo', data?.data);
              resolve();
            })
            .catch(err => {
              reject(err);
            });
      });
    },
    logout({ commit }) {
      // 清除token
      commit('setToken', '');
      commit('setUserInfo', {});
      // 清楚本地所有缓存数据
      window.localStorage.clear();
      window.sessionStorage.clear();
      // 退回到登录页面
      router.push('/login');
    }
  }
};

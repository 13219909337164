import Layout from '@/layout';

export default {
  path: '/project',
  component: Layout,
  redirect: '/project/atlas',
  // redirect: '/project/manage',
  name: '项目管理',
  meta: {
    title: '项目管理'
  },
  children: [
    {
      path: '/project/manage',
      name: '项目管理',
      component: () => import('@/views/Project/manage'),
      meta: {
        title: '项目管理'
      }
    },
    {
      path: '/project/atlas',
      name: '图集管理',
      component: () => import('@/views/Project/Atlas'),
      meta: {
        title: '图集管理'
      }
    },
    {
      path: '/project/module',
      name: '组件管理',
      component: () => import('@/views/Project/Module'),
      meta: {
        title: '组件管理'
      }
    },
    {
      path: '/project/connect-plan',
      name: '接线管理',
      component: () => import('@/views/Project/ConnectPlan'),
      meta: {
        title: '接线管理'
      }
    }
    /*{
      path: '/project/charge',
      name: '计费统计',
      component: () => import('@/views/Project/Charge'),
      meta: {
        title: '计费统计'
      }
    }*/
    // {
    //   path: '/look',
    //   name: 'Look',
    //   component: () => import('@/views/Project/manage/look.vue'),
    //   meta: {
    //     title: 'www'
    //   }
    // }
  ]
};

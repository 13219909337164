import axios from 'axios';
import { ElMessage } from 'element-plus';
import { download } from '@tmaito/utils';
// var baseUrl = 'http://192.168.0.119:9888';
var baseUrl = 'http://47.97.178.209';
// var baseUrl = 'https://chuiyang.solarqt.com:9169';
export default {
  namespaced: true,
  state: () => ({
    loading: false,
    total: 0,
    dataSource: [],
    page: {
      page: 1,
      limit: 20
    },
    filterParams: {
      brandName: '',
      comType: '',
      comCount: 0
    }
  }),
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setFilterParams(state, params) {
      state.filterParams = { ...params };
    },
    setData(state, res) {
      state.dataSource = [...res?.data];
      state.total = res?.total;
    },
    setPage(state, res) {
      state.page = {
        ...state.page,
        ...res
      };
    },
    setModal(state, res) {
      state = {
        ...state,
        ...res
      };
    }
  },
  actions: {
    queryData(context, params) {
      const { commit, state } = context;
      commit('setLoading', true);
      return new Promise((resolve, reject) => {
        const page = {
          ...state.page,
          ...(params?.page || {})
        };
        const filterParams = {
          ...state.filterParams,
          ...(params?.filterParams || {}),
          comCount: Number(params?.filterParams?.comCount || '0')
        };
        axios({
          url: baseUrl + '/api/ConnectPlan/PageData',
          method: 'POST',
          data: {
            ...page,
            params: {
              ...filterParams
            }
          }
        })
          .then(({ data }) => {
            if (data.code === 200) {
              commit('setPage', { ...page });
              commit('setFilterParams', { ...filterParams });
              const { total = 0, items = [] } = data?.data;
              commit('setData', {
                total,
                data: [...items]
              });
            } else {
              ElMessage.error(data.message);
            }
            resolve(data);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          })
          .finally(() => {
            setTimeout(() => {
              commit('setLoading', false);
            }, 600);
          });
      });
    },

    // 导出
    export(context, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: baseUrl + '/api/ConnectPlan/Export',
          method: 'POST',
          headers: {
            responseType: 'bolb'
          },
          data: {
            ...data,
            comCount: data.comCount || '0'
          }
        })
          .then(({ data }) => {
            if (data.code === 200) {
              // console.log(data, '566666666666');
              // console.log(`${location.origin}/${data.data}`, '5666666666667777');
              // console.log(location.origin, '566666666666////');
              download(`${location.origin}/${data.data}`);
            } else {
              ElMessage.error(data.message);
            }
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    Add(context, params) {
      params.comCount = params.comCount * 1;
      params.comPower = params.comPower * 1;
      params.comTotalPower = params.comTotalPower * 1;
      params.inverterPower = params.inverterPower * 1;
      params.volRatio = params.volRatio * 1;
      params.mttpQuantity = params.mttpQuantity * 1;
      const { commit, state } = context;
      commit('setLoading', true);
      console.log(context, 'context');
      console.log(params, 'params');
      console.log(state, 'state');
      return new Promise((resolve, reject) => {
        axios({
          url: baseUrl + '/api/ConnectPlan/Add',
          method: 'POST',
          data: {
            ...params
          }
        })
          .then(({ data }) => {
            if (data.code === 200) {
              ElMessage.success(data.message);
            } else {
              ElMessage.error(data.message);
            }
            resolve(data);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          })
          .finally(() => {
            setTimeout(() => {
              commit('setLoading', false);
            }, 600);
          });
      });
    }
  }
};

export default {
  token: (state) => state.user.token,
  userInfo: (state) => {
    let userInfo = sessionStorage.getItem('userInfo');
    userInfo = userInfo && JSON.parse(userInfo);
    return {
      ...state?.user?.userInfo,
      ...userInfo
    }
  },
  isLoadMenu: (state) => state.menu.isLoadMenu
};

import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import App from './App.vue';
import store from './store';
import router from './router';
import { provide } from "vue";
import * as echarts from 'echarts';
export default {
  setup() {
    provide('echarts', echarts)
  }
}

import 'element-plus/dist/index.css';
import './assets/styles/index.scss';

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.use(ElementPlus).use(store).use(router).mount('#app');
